@import "src/App.scss";

.authLayout {
  background-color: #fbfcfd;
  align-items: center;
  height: 100%;
  min-height: 100vh;
}

.container {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;

  &.white {
    background: white;
  }

  &.gray {
    background: gray;
  }
}

@import "styles/nprogress/style.scss";
@import "styles/aqua/style.scss";

.ant-dropdown-menu-item {
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  font-size: 15px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// Convert value of rem() sass mixin function
@function rem($px, $base: 16) {
  @return #{floor(($px/$base) * 100) / 100}rem; // to REMs
  // @return #{$px}px; // to PX's
}

.slide-fadein-up-enter {
  opacity: 0;
  transform: translateY(20px);
}

.slide-fadein-up-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s, transform 0.4s;
}

.slide-fadein-up-exit {
  opacity: 1;
  transform: translateY(0);
}

.slide-fadein-up-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s, transform 0.4s;
}

.ant-layout-sider-trigger {
  background: $gray-4;
  color: $gray-9;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-left {
  display: flex;
  align-items: center;
}

.flex-left-column {
  display: flex;
  flex-direction: column;
}

.reports-sider {
  .ant-tree-node-selected {
    background-color: transparent !important;
  }
  .ant-tree-treenode-checkbox-checked {
    .ant-tree-node-content-wrapper-normal {
      background-color: #bae7ff !important;
    }
  }
}

.reports-sider-schema {
  .ant-tree-node-selected {
    background-color: transparent !important;
  }
  .ant-tree-node-content-wrapper-open {
    background: #bae7ff !important;
  }
  .ant-tree-node-selected.ant-tree-node-content-wrapper-normal {
    background-color: #bae7ff !important;
  }
  .ant-tree-node-content-wrapper:hover {
    background-color: #bae7ff;
  }
}

.reportsTreeGray {
  :global(.ant-tree-list-holder-inner) {
    background: $gray-2;
  }
}

.filterModal {
  .ant-table-cell {
    padding: 10px;
  }
}

.multiSelectBoxes {
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }
}

.checkBoxSingleRender {
  label.ant-checkbox-wrapper {
    margin-left: 0px;
  }
}

.small-collapse-header .ant-collapse-item .ant-collapse-header {
  padding: 4px 12px;
}

:global {
  .ant-empty .body2 {
    color: black;
  }
  .ant-table-placeholder .ant-table-cell {
    border-bottom: none;
  }
}

.wrapText {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}

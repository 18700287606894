@import "src/App.scss";

.icon {
  text-align: center;
  padding-right: 30px;
  padding-left: 10px;
  right: 14px;
  width: 20px;
  top: 11px;
  margin: 0 !important;
  line-height: 1 !important;
  color: blue;
  font-size: rem(17);
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.title {
  font-size: rem(14);
}

.menuIcon {
  :global(.anticon) {
    font-size: 18.75px;
  }
}

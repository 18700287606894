$gray-1: #ffffff;
$gray-2: #fafafa;
$gray-3: #f5f5f5;
$gray-4: #f0f0f0;
$gray-5: #d9d9d9;
$gray-6: #bfbfbf;
$gray-7: #8c8c8c;
$gray-8: #595959;
$gray-9: #262626;
$gray-10: #000000;

$blue-1: #e6f7ff;
$blue-2: #bae7ff;
$blue-3: #91d5ff;
$blue-4: #69c0ff;
$blue-5: #40a9ff;
$blue-6: #1890ff;
$blue-7: #096dd9;
$blue-8: #0050b3;
$blue-9: #003a8c;
$blue-10: #002766;

$regular-weight: 400;
$medium-weight: 500;

.subtitle1 {
  font-size: 16px;
  letter-spacing: 0%;
  line-height: 24px;
  font-weight: $medium-weight;
}
.subtitle2 {
  font-style: normal;
  font-weight: $medium-weight;
  font-size: 14px;
  line-height: 22px;
}

.body1 {
  font-size: 14px;
  letter-spacing: 0%;
  line-height: 20px;
  font-weight: $regular-weight;
}

.body2 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.caption {
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}

.capitalize {
  text-transform: capitalize;
}

h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
}

h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
}

h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
}

.ant-btn-link {
  padding: 0 !important;
}

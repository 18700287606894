@import "src/App.scss";

.onboardingSider {
  background-color: $gray-2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.onboardingSiderSteps {
  margin-top: 75px;
  display: flex;
  align-items: center;
}

.onboardingTopBar {
  background-color: #002766;
}

.onboardingLayout {
  height: 100%;
  min-height: 100vh;
}
.onboardingContent {
  background-color: $gray-1;
}
.onboardingHeader {
  height: "64px";
  background-color: $blue-10;
  padding: 0px;
  display: flex;
  justify-content: space-between;
}

.onboardingLayoutLogoContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 240px;
  margin-left: 25px;
}

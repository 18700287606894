@import "src/App.scss";

.upgradePlanContainer {
  background-color: $gray-3;
  margin: 30px 0px 30px 0px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px;
}

.mainWidth {
  width: 190px;
}

.otherWidth {
  width: 340px;
}

.upgradeText {
  text-align: left;
  margin: 5px 0px 5px 0px;
}

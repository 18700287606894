@import "src/App.scss";

.mainSider {
  background-color: $gray-2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.mainSiderSteps {
  margin-top: 100px;
}

.mainTopBar {
  background-color: #002766;
}

.mainLayout {
  height: 100%;
  min-height: 100vh;
}

.mainContent {
  background-color: $gray-1;
}

.sitePageHeader {
  border-bottom: 1px solid $gray-4;
  padding-top: 9.5px;
  padding-bottom: 9.5px;
}

.sitePageContainer {
  padding: 25px;
}

.sitePageContainerReports {
  padding-top: 25px;
  padding-left: 25px;
}

.mainLayoutHeader {
  height: "64px";
  background-color: $blue-10;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mainLayoutSubHeader {
  display: flex;
  align-items: center;
  position: relative;
}

.avatar {
  font-size: 12px;
  line-height: 20px;
  background-color: $blue-6;
  cursor: pointer;
}

.mainLayoutLogoContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 240px;
  padding-left: 25px;
}

.userName {
  color: #ffffff;
  font-size: 16px;
  padding-left: 8px;
  padding-right: 24px;
  cursor: pointer;
}

.profileLink {
  color: inherit;
  transition: all 0.3s;

  &:hover {
    color: inherit;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
  }
}

@import "src/App.scss";

.mainLayoutHeader {
  height: "64px";
  background-color: $blue-10;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mainLayoutSubHeader {
  display: flex;
  align-items: center;
  position: relative;
}

.mainLayoutLogoContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 240px;
  padding-left: 25px;
}

@import "src/App.scss";

.connectionLayout {
  background-color: #fbfcfd;
  height: 100%;
  min-height: 100vh;
}

.connectionBodyLayout {
  flex-direction: row;
}

.subHeader {
  padding: 16px 24px;
  background-color: $gray-1;
  box-shadow: inset 0px -1px 0px $gray-4;
  h5 {
    margin-bottom: 0;
  }
}
